import React from 'react';

const FooterPanel = () => {
    const classStyle =
        "cursor-pointer hover:text-pink-400 text-center text-zinc-100 text-lg font-normal --webkit-font-['Onest'] leading-normal";

    return (
        <div className="bg-neutral-900 text-white py-12">
            <div className="flex flex-col md:flex-row justify-center gap-4 md:gap-8">
                <div className="mt-4 pr-0 md:pr-32 font-extrabold text-transparent text-xl bg-clip-text bg-gradient-to-r from-pink-400 to-purple-500 --webkit-font-['Unbounded']">
                    Deep Fake Face Swap AI Video Lab Limited
                </div>
                <p className={classStyle}
                   onClick={ () => {
                       window.open("https://deepfakex.ai/terms.html")
                   }}
                >
                    Terms of Service
                </p>
                <p className={classStyle}
                   onClick={ () => {
                       window.open("https://deepfakex.ai/privacy.html")
                   }}
                >
                    Privacy Policy
                </p>
                <p className={classStyle}
                >
                    Refund Policy
                </p>
                <p className={classStyle}
                   onClick={ () => {
                        window.open("mailto:frederick@deepfakex.ai")
                   }}
                >
                    Contact Us
                </p>
            </div>
        </div>
    );
};

export default FooterPanel;