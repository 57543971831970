import HeroBlock from "../Components/HeroBlock";
import React, {FC, MutableRefObject} from 'react';

import { Layout, Space } from 'antd';
import HeaderPanel from "../Components/HeaderPanel";
import FeaturesBlock from "../Components/FeaturesBlock";
import StepsBlock from "../Components/StepsBlock";
import FeedbackBlock from "../Components/FeedbackBlock";
import TryBlock from "../Components/TryBlock";
import FooterPanel from "../Components/FooterPanel";
import {useRef} from 'react';
const { Header, Footer, Sider, Content } = Layout;

export interface BlockProps {
    ref: React.MutableRefObject<any>
}

const LandingPage: FC = () => {
    const featuresRef = useRef(null);
    const stepsRef = useRef(null);
    const feedbackRef = useRef( null);
    const downloadRef = useRef(null);

    console.log(featuresRef);

    const handleClick = (ref: MutableRefObject<any>) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const onFeaturesClick = () => { handleClick(featuresRef)};
    const onStepsClick = () => { handleClick(stepsRef)}
    const onFeedbackClick = () => { handleClick(feedbackRef)}
    const onDownloadClick = () => { handleClick(downloadRef)}

    return (
        <div className="bg-neutral-900">
            <Space direction="vertical" style={{ width: '100%' }}>
                <Layout>
                    <Content>
                        <HeroBlock/>
                        <div ref={featuresRef}/>
                    </Content>
                    <FooterPanel/>
                </Layout>
            </Space>
        </div>
    );
};

export default LandingPage;