import React from 'react';

import heroImage from '../Resources/Hero Pic.png';
import * as amplitude from "@amplitude/analytics-browser";

const LeftPart = () => {
    return (
        <div className="flex flex-col-reverse lg:flex-col justify-start items-start gap-8">
            <div className="self-stretch flex-col justify-start items-start gap-14 flex">
                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                    <h1 className="font-extrabold text-transparent text-3xl bg-clip-text bg-gradient-to-r from-pink-400 to-purple-500 --webkit-font-['Unbounded'] leading-10">Revolutionizing Digital Reality</h1>
                    <p className="text-left text-zinc-100 text-2xl font-medium --webkit-font-['Onest']">At Deepfake Face Swap Video Lab Limited, we are at the forefront of artificial intelligence and machine learning innovation, specializing in creating cutting-edge deepfake facial transformation models. Our mission is to harness the power of technology to create realistic, high-quality facial swap videos, while setting high ethical standards in the development and use of these technologies.
                    </p>
                </div>
            </div>
            <div className="self-stretch justify-center items-center gap-6 inline-flex">
                <div
                    className="w-full md:max-w-md transition ease-in-out delay-75 grow shrink basis-0 h-14 p-2 bg-gradient-to-r from-pink-500 via-purple-800 to-indigo-400 rounded-3xl justify-center items-center gap-2.5 flex hover:cursor-pointer hover:-translate-y-1 hover:scale-105 duration-100"
                    onClick={() => window.open("https://app.deepfakex.ai?deviceId=" + amplitude.getDeviceId() + "&userId=" + amplitude.getUserId())}
                >
                    <div
                        className="text-center text-zinc-100 text-xl font-medium --webkit-font-['Onest'] leading-normal"
                    >
                        Get Started
                    </div>
                </div>
                {/*<div*/}
                {/*    className="transition ease-in-out delay-75 grow shrink basis-0 h-14 p-2 border-solid border-pink-500 rounded-3xl justify-center items-center gap-2.5 flex hover:cursor-pointer hover:-translate-y-1 hover:scale-105 duration-100"*/}
                {/*    onClick={() => window.open("https://apps.apple.com/us/app/face-ai-deep-fake-video-swap/id6449958580")}*/}
                {/*>*/}
                {/*    <div className="text-center text-zinc-100 text-xl font-medium --webkit-font-['Onest'] leading-normal">Buy Premium</div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

const HeroBlock = () => {
    return (
        <div className="flex flex-col-reverse lg:flex-row bg-neutral-900 justify-start items-center gap-16 px-8 xl:px-48 py-8 xl:py-16">
            <LeftPart/>
            <video
                className="w-64 md:w-80 rounded-3xl hover:cursor-pointer border-solid border-neutral-600 border-2" src="https://deepfakex.ai/IMG_2104.mp4" autoPlay muted loop playsInline
                onClick={() => {
                    window.open("https://app.deepfakex.ai?deviceId=" + amplitude.getDeviceId());
                }}
            />
        </div>
    );
};

export default HeroBlock;